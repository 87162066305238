import router from "@/router";

const state = {
  editionsIds: [] as number[],
};

const mutations = {
  SAVE_COMPANY_EDITIONS(companyEditionsState: any, payload: any) {
    companyEditionsState.editionsIds = payload;
  },
};

const actions = {
  getCompanyEditions({ commit, rootState, dispatch }: any) {
    const boughtEditions = [] as number[];
    if (rootState.company.company.packages) {
      rootState.company.company.packages.forEach((element: any) => {
        boughtEditions.push(element.edition_id);
      });
    }
    commit("SAVE_COMPANY_EDITIONS", boughtEditions);

    const selectedEdition = Number(router.currentRoute.query.edition);

    if (selectedEdition && boughtEditions.includes(selectedEdition, 0)) {
      dispatch("setActiveEdition", selectedEdition);
    } else {
      dispatch("setActiveEdition", boughtEditions[boughtEditions.length - 1]);
    }
  },

  setActiveEdition({ commit }: any, id: any) {
    commit("SAVE_ACTIVE_EDITION", id);
    commit("SAVE_VISUALISATION_URL", "");
  },
};

const getters = {
  companyEditions: (companyEditionsState: any) => {
    return companyEditionsState.editionsIds
      ? companyEditionsState.editionsIds
      : null;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
